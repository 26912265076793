class Details {
  private button: HTMLElement;
  private isOpen: boolean = false;

  constructor(private container: HTMLElement) {
    this.button = container.querySelector(':scope > button');

    this.button.addEventListener('click', () => {
      this.toggle();
    });
  }

  private toggle() {
    this.isOpen = !this.isOpen;
    this.container.classList.toggle('show');
    this.button.setAttribute('aria-expanded', this.isOpen.toString());
  }

  static automount() {
    document.querySelectorAll<HTMLElement>('.details').forEach((element) => {
      new Details(element);
    });
  }
}

export default Details;

import 'inline:keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

for(const el of document.querySelectorAll<HTMLDivElement>('.slider')) {
  const slides = el.querySelector<HTMLDivElement>('.slides');
  let inert = false;
  let dragStart = 0;
  let interval = 0;
  const loop = el.dataset.loop === 'true';

  const slider = new KeenSlider(slides, {
    selector: '.slide',
    loop: el.dataset.loop === 'true',
    slides: {
      perView: 'auto'
    },
    dragStarted: (s) => {
      dragStart = s.track.details.position;
    },
    dragged: (s) => {
      if(!inert && Math.abs(s.track.details.position - dragStart) > 0.02) {
        // make contents inert
        s.slides.forEach((slide) => {
          slide.setAttribute('inert', '');
        });
        inert = true;

        // stop autoplay
        if(interval) {
          clearInterval(interval);
          interval = 0;
        }
      }
    },
    dragEnded: (s) => {
      // make contents active
      s.slides.forEach((slide) => {
        slide.removeAttribute('inert');
      });
      inert = false;
    }
  })

  const dots = el.querySelector<HTMLDivElement>('.dots')
  if(dots) {
    slider.on('slideChanged', (s) => {
      for(const child of dots.querySelectorAll('button')) {
        child.classList.remove('active');
      }
      const child = dots.querySelectorAll('button')[s.track.details.abs];
      if(child) {
        child.classList.add('active');
      }
    })
    for(const [i, child] of Array.from(dots.querySelectorAll('button')).entries()) {
      child.addEventListener('click', () => {
        slider.moveToIdx(i);
        if(interval) {
          clearInterval(interval);
          interval = 0;
        }
      }, false)
    }
  }

  const prev = el.querySelector<HTMLButtonElement>('[name=prev]')
  if(prev) {
    prev.addEventListener('click', () => {
      slider.prev();
      if(interval) {
        clearInterval(interval);
        interval = 0;
      }
    }, false)
  }
  const next = el.querySelector<HTMLButtonElement>('[name=next]')
  if(next) {
    next.addEventListener('click', () => {
      slider.next();
      if(interval) {
        clearInterval(interval);
        interval = 0;
      }
    }, false)
  }
  if(prev || next) {
    new ResizeObserver(() => {
      if(slider.track.details.length < 1) {
        next && (next.hidden = true);
        prev && (prev.hidden = true);
        if(slider.track.details.abs > 0) {
          slider.moveToIdx(0);
        }
      } else {
        next && (next.hidden = false);
        prev && (prev.hidden = false);
      }
    }).observe(slides)
  }

  if(el.dataset.autoplay) {
    interval = setInterval(() => {
      if(slider.track.details.length < 1) {
        return;
      }

      if(loop || slider.track.details.abs < slider.slides.length - 1) {
        slider.next();
      } else {
        slider.moveToIdx(0);
      }
    }, parseFloat(el.dataset.autoplay) * 1000)
  }
}

import Details from "./lib/details";
import Select from "./lib/select";
import MessageForm from "./lib/messageform";

import "./lib/form";
import "./lib/dialog";
import "./lib/slider";

Details.automount();
Select.automount();
MessageForm.automount();

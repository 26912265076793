export default class MessageForm {
  constructor(private form: HTMLFormElement) {
    this.form.addEventListener("submit", this.onSubmit.bind(this));
  }

  private async onSubmit(event: Event) {
    event.preventDefault();
    this.form.querySelectorAll("button").forEach((b) => b.disabled = true);

    // remove error divs
    this.form.querySelectorAll(".error,.success").forEach((element) => {
      element.remove();
    });

    const formData = new FormData(this.form);
    const url = this.form.getAttribute("action");
    const method = this.form.getAttribute("method");

    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Object.fromEntries(formData.entries())),
    })

    if(response.ok) {
      const data = await response.json();

      // add success message
      const success = document.createElement("div");
      success.classList.add("success");
      success.appendChild(document.createTextNode(data.message));
      this.form.prepend(success);

    } else {
      if(response.status == 400) {
        const data:Record<string,string> = await response.json();
        for(const [key, value] of Object.entries(data)) {
          const input = this.form.querySelector(`[name="${key}"]`);
          if(input) {
            const error = document.createElement("div");
            error.classList.add("error");
            error.appendChild(document.createTextNode(value));
            input.after(error);
          }
        }
      } else {
        const data = await response.text();
        const error = document.createElement("div");
        error.classList.add("error");
        error.appendChild(document.createTextNode(data));
        this.form.prepend(error);
      }
    }

    this.form.querySelectorAll("button").forEach((b) => b.disabled = false);
  }

  static automount() {
    document.querySelectorAll<HTMLFormElement>("form.message").forEach((form) => {
      new MessageForm(form as HTMLFormElement);
    });
  }
}

type Option = [label:string, value:string];
type OptionGrid = [label: '', options:Option[]];
type OptGroup = [label:string, options:Options];
type Options = (Option|OptGroup|OptionGrid)[];
import type {AjaxSuccess} from './form';

function isOptGroup(option: Option|OptGroup|OptionGrid): option is OptGroup {
  return option.length === 2 && option[1] instanceof Array && option[0] !== '';
}

function isOption(option: Option|OptGroup|OptionGrid): option is Option {
  return option.length === 2 && !(option[1] instanceof Array);
}

function isOptionGrid(option: Option|OptGroup|OptionGrid): option is OptionGrid {
  return option.length === 2 && option[0] === '' && option[1] instanceof Array;
}


const selectedClasses = ['bg-primary', 'text-white', 'hover:bg-primary-dark', 'hover:text-white', 'focus:bg-primary-dark', 'focus:text-white'];

class Select {
  private button: HTMLElement;
  private isOpen: boolean = false;
  private options: Options = [];
  private popover: HTMLElement;
  private clickOutsideListener: (event: MouseEvent) => void;
  private fragment: DocumentFragment;
  private input: HTMLInputElement;
  private valueLabel: HTMLElement;
  private isLabelValueMode: boolean = false;
  private isGridMode: boolean = false;
  private _expandedGroup: HTMLButtonElement;
  private _value: string;
  private valueIndex: Map<string, {label: string, button: HTMLButtonElement, optGroupButton: HTMLButtonElement|null}> = new Map();
  private dontFireChange: boolean = false;

  constructor(private container: HTMLElement) {
    this.button = container.querySelector(':scope > button');
    this.valueLabel = this.button.querySelector(':scope > span');
    this.popover = container.querySelector(':scope > .popover');
    this.options = JSON.parse(this.container.querySelector(':scope > script').innerHTML);
    this.fragment = document.createDocumentFragment();

    if(this.container.dataset.labelvalue !== undefined) {
      this.isLabelValueMode = true;
    }

    if(this.container.dataset.grid !== undefined) {
      this.isGridMode = true;
    }

    // create a hidden input to store the value
    const input = container.querySelector<HTMLInputElement>('input[type="hidden"]');
    this.input = input;

    const form = this.container.closest('form');
    if(form) {
      form.addEventListener('reset', () => {
        if(this.input.dataset.base !== undefined) {
          this.value = this.input.dataset.base;
        } else {
          this.value = this.firstOption(this.options)[1];
        }
      });
    }

    this.button.addEventListener('click', () => {
      this.toggle();
    });

    this.container.addEventListener('keydown', (event) => {
      if(event.code === 'Escape') {
        this.close();
      }
      if(event.code === 'ArrowDown') {
        // select next option
        const keys = Array.from(this.valueIndex.keys());

        const currentIndex = keys.indexOf(this.value);

        if(currentIndex > -1 && currentIndex < keys.length - 1) {
          const next = keys[currentIndex + 1];
          this.value = next;
          this.valueIndex.get(next)?.button.focus();
          event.preventDefault();
        }
      }
      if(event.code === 'ArrowUp') {
        // select previous option
        const keys = Array.from(this.valueIndex.keys());

        const currentIndex = keys.indexOf(this.value);

        if(currentIndex > 0) {
          const next = keys[currentIndex - 1];
          this.value = next;
          this.valueIndex.get(next)?.button.focus();
          event.preventDefault();
        }
      }
    }, true);

    // wait a tick to avoid blocking the main thread
    setTimeout(() => {
      this.buildDom();
    }, 0);

    document.addEventListener('ajax:success', (event: CustomEvent<AjaxSuccess>) => {
      const {response} = event.detail;

      // search for this select in the response
      const select = response.querySelector(`input[name="${this.input.name}"]`)?.closest('.select');
      //update options if found
      if(select) {
        // check if the options have changed
        const newOptions = JSON.parse(select.querySelector(':scope > script').innerHTML);
        if(JSON.stringify(newOptions) !== JSON.stringify(this.options)) {
          this.options = newOptions;
          this.valueIndex.clear();
          this.popover.innerHTML = '';

          this.buildDom();
        }
      }
    }, true);
  }

  buildDom() {
    this.options.forEach((option) => {
      if(option instanceof Array) {
        if(isOptGroup(option)) {
          const [labelButton, container] = this.createOptGroup(option);
          this.fragment.appendChild(container);
        } else {
          this.fragment.appendChild(this.createOption(option));
        }
      }
    });

    this.popover.appendChild(this.fragment);
    if(this.valueIndex.has(this.input.value)) {
      this.value = this.input.value;
    } else {
      this.value = this.firstOption(this.options)[1];
    }
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    // remove "selected classes" from previous selected option
    const previous = this.valueIndex.get(this._value);
    if(previous) {
      previous.button.classList.remove(...selectedClasses);
    }

    // store new value
    this._value = value;

    // update button label
    this.valueLabel.innerHTML = this.isLabelValueMode ? value : this.valueIndex.get(value)?.label ?? '';

    // add "selected classes" to new selected option
    const current = this.valueIndex.get(value);
    if(current) {
      current.button.classList.add(...selectedClasses);
      // open the optgroup if the selected option is in one
      if(current.optGroupButton && current.optGroupButton !== previous?.optGroupButton) {
        this.expandedGroup = current.optGroupButton;
      }
    }

    // emit change event
    value = value ?? '';
    if(this.input.value !== value) {
      this.input.value = value;
      if(!this.dontFireChange) {
        this.input.dispatchEvent(new Event('change', {bubbles: true}));
      }
    }
  }

  firstOption(options: Options): Option {
    const option = options[0];

    if(isOptionGrid(option)) {
      return this.firstOption(option[1]);
    } else if(isOptGroup(option)) {
      return this.firstOption(option[1]);
    } else {
      return option;
    }
  }

  closeGroup(group: HTMLButtonElement) {
    group.ariaExpanded = 'false';
    group.parentElement.style.gridTemplateRows = 'min-content 0fr';
    group.nextElementSibling.querySelectorAll<HTMLElement>(':scope button').forEach((element) => {
      element.tabIndex = -1;
    });
  }

  expandGroup(group: HTMLButtonElement) {
    group.ariaExpanded = 'true';
    group.parentElement.style.gridTemplateRows = 'min-content 1fr';
    group.nextElementSibling.querySelectorAll<HTMLElement>(':scope button').forEach((element) => {
      element.tabIndex = 0;
    });
  }

  set expandedGroup(group: HTMLButtonElement | null) {
    if(this._expandedGroup) {
      this.closeGroup(this._expandedGroup);
    }

    this._expandedGroup = group;

    if(group) {
      this.expandGroup(group);
    }
  }

  private createOption(option: Option): HTMLElement {
    const element = document.createElement('button');
    element.type = 'button';
    element.role = 'option';
    element.value = option[1];

    element.classList.add('text-left', 'px-4', 'py-2', 'hover:bg-gray-200', 'focus:bg-gray-200', 'focus:outline-none');
    element.innerHTML = option[0];
    this.valueIndex.set(option[1], {label: option[0], button: element, optGroupButton: null})

    element.addEventListener('click', () => {
      this.value = option[1];
      this.close();
    }, true);

    return element;
  }

  private createOptGroup(optGroup: OptGroup): [HTMLButtonElement, HTMLElement] {
    const [label, options] = optGroup;

    const div = document.createElement('div');
    div.classList.add('grid', 'grid-cols-1');
    div.style.transition = 'grid-template-rows 0.2s ease-in-out';
    div.style.gridTemplateRows = 'min-content 0fr';

    const groupButton = document.createElement('button');
    groupButton.classList.add('text-left', 'bg-gray-100', 'p-2', 'hover:bg-gray-200', 'focus:bg-gray-200', 'focus:outline-none', 'whitespace-nowrap');
    groupButton.type = 'button';
    groupButton.role = 'option';
    groupButton.ariaExpanded = 'false';

    groupButton.innerHTML = label;
    groupButton.addEventListener('click', () => {
      if(groupButton.ariaExpanded === 'false') {
        this.expandedGroup = groupButton;
      } else {
        this.expandedGroup = null;
      }
    }, true);

    const contents = document.createElement('div');
    contents.classList.add('grid', 'grid-cols-1', 'overflow-hidden');
    contents.style.gridAutoRows = 'auto';

    options.forEach((option) => {
      let child = null;
      if(isOptGroup(option)) {
        // nested optgroup not supported
        return;
      } else if(isOptionGrid(option)) {
        child = this.createOptionGrid(option[1]);
        // for each option in the grid, set the optgroup button
        option[1].forEach((option) => {
          this.valueIndex.get(option[1]).optGroupButton = groupButton;
        });
      } else {
        child = this.createOption(option);
        this.valueIndex.get(option[1]).optGroupButton = groupButton;
      }

      // prevent focus
      child.tabIndex = -1;
      contents.appendChild(child);
    });

    div.appendChild(groupButton);
    div.appendChild(contents);

    return [groupButton, div];
  }

  private createOptionGrid(option: Option[]): HTMLElement {
    const element = document.createElement('div');
    element.classList.add('grid', 'grid-cols-3', 'p-4', 'm-4', 'border', 'border-gray-200');

    option.forEach((option) => {
      const child = this.createOption(option);
      child.classList.add('text-center');
      child.classList.remove('text-left');
      element.appendChild(child);
    });

    return element;
  }

  private toggle() {
    if(this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  private close() {
    this.isOpen = false;
    this.container.removeAttribute('open');
    this.button.ariaExpanded = 'false';

    if(this.clickOutsideListener) {
      document.removeEventListener('click', this.clickOutsideListener);
      this.clickOutsideListener = null;
    }

    this.button.focus();

    this.expandedGroup = null;
  }

  private open() {
    this.isOpen = true;
    this.container.setAttribute('open', '');
    this.button.ariaExpanded = 'true';
    const ogb = this.valueIndex.get(this.value)?.optGroupButton;
    if(ogb) {
      this.expandedGroup = ogb;
    }

    if(!this.clickOutsideListener) {
      this.clickOutsideListener = (event) => {
        if(!this.container.contains(event.target as Node)) {
          this.close();
        }
      };

      document.addEventListener('click', this.clickOutsideListener);
    }
  }

  static automount() {
    document.querySelectorAll<HTMLElement>('.select').forEach((element) => {
      new Select(element);
    });
  }
}

export default Select;

function getButton(target: EventTarget): HTMLButtonElement | null {
  if(target instanceof HTMLButtonElement) {
    return target;
  }
  if(target instanceof HTMLElement) {
    return target.closest('button');
  }
  return null;
}

function getDialogContent(target: EventTarget): HTMLDivElement | null {
  if(target instanceof HTMLElement) {
    return target.closest('div.content');
  }
  return null;
}

let lastDialog: HTMLDialogElement | null = null;

document.body.addEventListener('click', (e) => {
  if(lastDialog && !getDialogContent(e.target)) {
    lastDialog.close();
    lastDialog = null;
  }

  const target = getButton(e.target);

  if(lastDialog && target && target.matches('.close')) {
    lastDialog.close();
    lastDialog = null;
    return;
  }

  if(!target) {
    return;
  }

  if('dialog' !== target.getAttribute('aria-haspopup')) {
    return;
  }

  const dialog = document.getElementById(target.getAttribute('aria-controls') || '');
  if(!dialog || !(dialog instanceof HTMLDialogElement)) {
    return;
  }

  dialog.showModal();
  lastDialog = dialog;
}, true);

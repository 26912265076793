const filters = document.querySelector<HTMLFormElement>('form.filters');
const cache = new Map<string, string>();

export type AjaxSuccess = {
  response: Document;
};

let throttleTimer = 0;
let throttleCancel: (() => void) | null = null;

if(filters) {
  let lastSearch = location.search;

  async function search(query: string) {
    const url = new URL(location.href);
    url.search = query;

    // since a new search is being done, remove the paging parameter
    url.searchParams.delete('page');

    window.history.replaceState({}, '', url.href);

    let html = cache.get(url.href);
    if(!html) {
      const timer = setTimeout(() => {
        console.log('slow connection');
      }, 400);

      if(throttleTimer) {
        // cancel the previous request
        clearTimeout(throttleTimer);
        throttleCancel();
      }

      try {
        await new Promise((resolve, reject) => {
          throttleCancel = reject;

          throttleTimer = setTimeout(async() => {
            html = await fetch(url.href, {
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
              },
            }).then(response => response.text());

            clearTimeout(timer);
            resolve(html);
          }, 50);

        });
      } catch(e) {
        // this request was cancelled before it started
        // exit early
        return;
      } finally {
        throttleTimer = 0;
        throttleCancel = null;
      }
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // check if the doc has a qstr meta tag
    const qstr = doc.querySelector<HTMLMetaElement>('meta[name="qstr"]');
    if(qstr) {
      const value = qstr.content;
      if(value !== undefined) {
        lastSearch = value;
        const baseUrl = new URL(location.href);
        baseUrl.search = value;
        cache.set(baseUrl.href, html);
        window.history.replaceState({}, '', value);
      }
    }

    // replace the content
    const content = document.querySelector<HTMLElement>('[data-content]');
    if(content) {
      content.innerHTML = doc.querySelector('[data-content]').innerHTML;
    }

    // search for <output> elements
    const outputs = document.querySelectorAll<HTMLOutputElement>('output');
    for(const output of outputs) {
      const id = output.id;
      if(id) {
        const newOutput = doc.getElementById(id);
        if(newOutput) {
          output.innerHTML = newOutput.innerHTML;
        }
      }
    }

    // fire a custom event (custom select elements might need to be updated)
    const event = new CustomEvent<AjaxSuccess>('ajax:success', {
      detail: {
        response: doc,
      },
    });
    document.dispatchEvent(event);
  }

  function onSubmit() {
    const formData = new FormData(filters);

    // replace history state
    const url = new URL(location.href);


    // remove known keys
    const names = new Set([...filters.querySelectorAll('[name]')].map(el => el.getAttribute('name')));
    for(const key of names) {
      url.searchParams.delete(key);
    }

    // set non-empty values
    for(const [key, value] of formData.entries()) {
      const v = value.toString();

      if(v.length > 0) {
        // get the input element for this key
        const input = filters.querySelector<HTMLInputElement>(`[name="${key}"]`);
        // check if this has a base value set
        const base = input.dataset.base;
        if(base === undefined || v !== base) {
          url.searchParams.append(key, v);
        }
      }
    }

    // check if search changed
    const newSearch = url.search;

    if(lastSearch !== url.search) {
      lastSearch = newSearch;

      search(newSearch);
    }
  }

  filters.addEventListener('reset', (e) => {
    e.preventDefault();

    // remove all checks
    const checks = filters.querySelectorAll<HTMLInputElement>('input:checked');
    for(const check of checks) {
      check.checked = false;
    }

    // remove all values
    const values = filters.querySelectorAll<HTMLSelectElement>('select,input[type="text"],input[type="search"]');
    for(const select of values) {
      select.value = '';
    }

    onSubmit();
  }, true);

  filters.addEventListener('submit', (e) => {
    e.preventDefault();
    onSubmit();
  }, true);

  // listen for changes
  filters.addEventListener('change', (e) => {
    e.preventDefault();
    onSubmit();
  }, true);
}

const whenRulesSets = document.querySelectorAll<HTMLScriptElement>('script.when-rules');
type WhenRules = {
  [controlled: string]: {
    [contoller: string]: string;
  }
};

function applyWhenRules(rulesSet: WhenRules, form: HTMLFormElement) {
  const data = new FormData(form);

  for(const [controlled, rules] of Object.entries(rulesSet)) {
    const visible = Object.entries(rules).every(([controller, value]) => {
      if(data.getAll(controller).pop() === value) {
        return true;
      }
      return false;
    });

    const controlledElement = form.querySelector<HTMLInputElement>(`[data-when="${controlled}"]`);
    if(controlledElement) {
      if(visible) {
        controlledElement.style.display = '';
      } else {
        controlledElement.style.display = 'none';
      }
    }
  }
}

for (const whenRulesScript of whenRulesSets) {
  const form = whenRulesScript.closest('form');
  const whenRules = JSON.parse(whenRulesScript.innerHTML) as WhenRules;

  form.addEventListener('input', () => {
    applyWhenRules(whenRules, form);
  })

  applyWhenRules(whenRules, form);
}
